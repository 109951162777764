import {
  ActivityEntity,
  TaskCommentEntity,
  TaskEntity,
  TaskSetEntity,
  TaskSetStage,
  TaskSetType,
  TaskState,
  UserEntity,
} from '@mentessa/types';
import { Action } from '@/store/types';

export const moduleName = 'tasks';

export interface MentoringUserRecord {
  user: UserEntity;
  tasks: {
    goals: TaskSetEntity;
    weekly: TaskSetEntity;
  };
}

export interface MentoringUserMap {
  [id: number]: MentoringUserRecord;
}

export interface TimePeriod {
  begin: Date;
  end: Date;
}

export interface TasksState {
  activityFeed: ActivityEntity[];
  mentor?: MentoringUserRecord;
  mentees?: MentoringUserRecord[];
  mentee?: MentoringUserRecord;
  comments?: TaskCommentEntity[];
  canCreateNew: {
    goals: boolean;
    tasks: boolean;
  };
  period: TimePeriod;
  all: TaskSetEntity[];
  closed: TaskSetEntity[];
}

export enum TasksActions {
  LoadMentor = '[Tasks] Load Mentor',
  LoadMentees = '[Tasks] Load Mentees',
  LoadTasks = '[Tasks] Load Tasks',
  SendTasks = '[Tasks] Send Tasks',
  SelectMentee = '[Tasks] Select Mentee',
  SetTaskSetStage = '[Tasks] Set Task Set Stage',
  SetTaskDone = '[Tasks] Set Task Done',
  SetTaskEvaluated = '[Tasks] Set Task Evaluated',
  SetTaskProgress = '[Tasks] Set Task Progress',
  LoadEvents = '[Tasks] Load Events',
  ReadEvent = '[Tasks] Read Event',
  LoadTaskComments = '[Tasks] Load Task Comments',
  SendTaskComment = '[Tasks] Send Task Comment',
  LoadAllEvents = '[Task] Load All Events',
  CheckNewAvailability = '[Task] Check New Availability',
  SetTaskState = '[Task] Set Task State',
  SetTaskText = '[Task] Set Task Text',
  DeleteTask = '[Task] Delete Task',
  LoadTasksPeriod = '[Task] Load Tasks Period',
  LoadPausedTasks = '[Tasks] Load Paused Tasks',
  LoadAllTasks = '[Tasks] Load All Tasks',
  LoadClosedTasks = '[Tasks] Load Closed Tasks',
  LoadMentoringPairTasks = '[Tasks] Load Mentoring Pair Tasks',
}

export enum TasksMutations {
  SetMentor = '[Tasks] Set Mentor',
  SetMentees = '[Tasks] Set Mentees',
  SetTaskSetForMentee = '[Tasks] Set TaskSet For Mentee',
  SetTaskSetForMentor = '[Tasks] Set TaskSet For Mentor',
  SetMentee = '[Tasks] Set Mentee',
  SetTaskSetStage = '[Tasks] Set Task Set Stage',
  FindTaskSetAndSetStage = '[Tasks] Find Task Set And Set Stage',
  FindTaskAndSetProgress = '[Tasks] Find Task And Set Progress',
  FindTaskAndSetText = '[Tasks] Find Task And Set Text',
  FindTaskAndSetState = '[Tasks] Find Task And Set State',
  FindTaskAndDelete = '[Tasks] Find Task And Delete',
  FindTaskSetAndClear = '[Tasks] Find Task Set And Clear',
  SetTaskDone = '[Tasks] Set Task Done',
  SetTaskEvaluated = '[Tasks] Set Task Evaluated',
  SetTaskProgress = '[Tasks] Set Task Progress',
  SetEvents = '[Tasks] Set Events',
  AppendEvent = '[Tasks] Append Event',
  SetEventRead = '[Task] Set Event Read',
  SetTaskComments = '[Task] Set Task Comments',
  AppendTaskComment = '[Task] Append Task Comment',
  SetNewAvailability = '[Task] Set New Availability',
  SetTaskState = '[Task] Set Task State',
  SetTaskText = '[Task] Set Task Text',
  RemoveTaskFromTaskSet = '[Task] Remove Task From TaskSet',
  SetPeriod = '[Task] Set Period',
  SetAllTasks = '[Tasks] Set All Tasks',
  SetClosedTasks = '[Tasks] Set Closed Tasks',
}

export class LoadMentor implements Action {
  type = `${moduleName}/${TasksActions.LoadMentor}`;
}

export class LoadMentees implements Action {
  type = `${moduleName}/${TasksActions.LoadMentees}`;
}

export class LoadTasks implements Action {
  type = `${moduleName}/${TasksActions.LoadTasks}`;
}

export class SendTasks implements Action {
  type = `${moduleName}/${TasksActions.SendTasks}`;

  constructor(public taskSet: TaskSetEntity) {
    this.taskSet = taskSet;
  }
}

export class SelectMentee implements Action {
  type = `${moduleName}/${TasksActions.SelectMentee}`;

  constructor(public mentee: MentoringUserRecord) {
    this.mentee = mentee;
  }
}

export class SetTaskSetStage implements Action {
  type = `${moduleName}/${TasksActions.SetTaskSetStage}`;

  constructor(public taskSet: TaskSetEntity, public stage: TaskSetStage) {
    this.taskSet = taskSet;
    this.stage = stage;
  }
}

export class SetTaskDone implements Action {
  type = `${moduleName}/${TasksActions.SetTaskDone}`;

  constructor(public task: TaskEntity, public status: boolean) {
    this.task = task;
    this.status = status;
  }
}

export class SetTaskEvaluated implements Action {
  type = `${moduleName}/${TasksActions.SetTaskEvaluated}`;

  constructor(public task: TaskEntity, public status: boolean) {
    this.task = task;
    this.status = status;
  }
}

export class SetTaskProgress implements Action {
  type = `${moduleName}/${TasksActions.SetTaskProgress}`;

  constructor(public task: TaskEntity, public value: number) {
    this.task = task;
    this.value = value;
  }
}

export class LoadEvents implements Action {
  type = `${moduleName}/${TasksActions.LoadEvents}`;

  constructor(public mentor: UserEntity, public mentee: UserEntity) {
    this.mentee = mentee;
    this.mentor = mentor;
  }
}

export class ReadEvent implements Action {
  type = `${moduleName}/${TasksActions.ReadEvent}`;

  constructor(public event: ActivityEntity) {
    this.event = event;
  }
}

export class LoadTaskComments implements Action {
  type = `${moduleName}/${TasksActions.LoadTaskComments}`;

  constructor(public task: TaskEntity) {
    this.task = task;
  }
}

export class SendTaskComment implements Action {
  type = `${moduleName}/${TasksActions.SendTaskComment}`;

  constructor(public task: TaskEntity, public value: TaskCommentEntity) {
    this.task = task;
    this.value = value;
  }
}

export class LoadAllEvents implements Action {
  type = `${moduleName}/${TasksActions.LoadAllEvents}`;

  constructor(public date?: Date) {
    this.date = date;
  }
}

export class CheckNewAvailability implements Action {
  type = `${moduleName}/${TasksActions.CheckNewAvailability}`;

  constructor(public mentor: UserEntity | { id: number }, public mentee: UserEntity | { id: number }) {
    this.mentee = mentee;
    this.mentor = mentor;
  }
}

export class SetTaskState implements Action {
  type = `${moduleName}/${TasksActions.SetTaskState}`;

  constructor(public task: TaskEntity, public state: TaskState) {
    this.task = task;
    this.state = state;
  }
}

export class SetTaskText implements Action {
  type = `${moduleName}/${TasksActions.SetTaskText}`;

  constructor(public task: TaskEntity, public text: string) {
    this.task = task;
    this.text = text;
  }
}

export class DeleteTask implements Action {
  type = `${moduleName}/${TasksActions.DeleteTask}`;

  constructor(public task: TaskEntity) {
    this.task = task;
  }
}

export class LoadTasksPeriod implements Action {
  type = `${moduleName}/${TasksActions.LoadTasksPeriod}`;
}

export class LoadPausedTasks implements Action {
  type = `${moduleName}/${TasksActions.LoadPausedTasks}`;

  constructor(public mentee: UserEntity, public taskSetType: TaskSetType) {
    this.mentee = mentee;
    this.taskSetType = taskSetType;
  }
}

export class LoadAllTasks implements Action {
  type = `${moduleName}/${TasksActions.LoadAllTasks}`;
}

export class LoadClosedTasks implements Action {
  type = `${moduleName}/${TasksActions.LoadClosedTasks}`;
}

export class LoadMentoringPairTasks implements Action {
  type = `${moduleName}/${TasksActions.LoadMentoringPairTasks}`;

  constructor(public mentor: UserEntity | { id: number }, public mentee: UserEntity | { id: number }) {
    this.mentee = mentee;
    this.mentor = mentor;
  }
}
