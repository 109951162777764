






































































































































































































import Vue, { PropType } from 'vue';
import {
  MentoringType,
  QuestionnaireEntity,
  TaskSetEntity,
  TaskSetStage,
  TaskSetType,
  UserEntity,
} from '@mentessa/types';
import TaskList from '@/components/DashboardTaskList/TaskList.vue';
import Badge from '@/components/Badge.vue';
import { format, parseISO } from 'date-fns';
import { ShowFeedback } from '@/store/ui';
import { getRatingTotal, taskSetSuccessRate } from '@/utils/ratings';
import { LoadQuestionnaire } from '@/store/utils';

function formatRating(rating?: number) {
  return rating != null ? `${(rating * 5).toFixed(1)}` : '-';
}

function formatDate(date: string) {
  return format(parseISO(date), 'd.M.yyyy');
}

function compareMonth(dateStr: string, date2Str: string, monthShift = 0) {
  const parsedDate = parseISO(dateStr);
  const parsedDate2 = parseISO(date2Str);
  if (monthShift) {
    parsedDate.setMonth(parsedDate.getMonth() - monthShift);
  }
  return parsedDate.getFullYear() === parsedDate2.getFullYear() && parsedDate.getMonth() === parsedDate2.getMonth();
}

export default Vue.extend({
  name: 'Evaluation',
  components: { Badge, TaskList },
  props: {
    mentor: Object as PropType<UserEntity>,
    mentee: Object as PropType<UserEntity>,
    taskSets: { type: Array as PropType<Array<TaskSetEntity>>, default: () => [] },
  },
  data: () => ({
    ranks: [['-', '-']],
  }),
  computed: {
    target() {
      return this.$route.query.details === 'mentor' ? this.mentor : this.mentee;
    },
    pair() {
      return this.$route.query.details === 'mentor' ? this.mentee : this.mentor;
    },
    taskSetsToShow() {
      if (this.taskSets?.length) {
        return this.taskSets;
      }
      if (this.mentee?.taskSets?.length) {
        return this.mentee.taskSets;
      }
      return [];
    },
    monthlyGoals(): TaskSetEntity[] {
      return this.taskSetsToShow
        .filter((ts) => ts.type === TaskSetType.Monthly && ts.mentorId === this.mentor.id)
        .sort((ts1, ts2) => ts2.id - ts1.id);
    },
    weeklyTasks(): TaskSetEntity[] {
      return this.taskSetsToShow
        .filter((ts) => ts.type === TaskSetType.Weekly && ts.mentorId === this.mentor.id)
        .sort((ts1, ts2) => ts2.id - ts1.id);
    },

    ratings() {
      const taskSets = this.monthlyGoals.filter((taskSet) => taskSet.stage === TaskSetStage.Close);
      return taskSets.map((taskSet) => {
        const questionnaire = taskSet.questionnaires.find((q) => q.targetId === this.target.id);
        const weeklyTaskSets = this.weeklyTasks.filter((wt) => compareMonth(wt.due, taskSet.due));
        const totalTasks = weeklyTaskSets.reduce((total, taskSet) => total + taskSet.tasks.length, 0);
        let tasks;
        if (this.target.attributes.mentoringType === MentoringType.Mentor) tasks = totalTasks;
        else {
          if (totalTasks === 0) tasks = 0;
          const doneTasks = weeklyTaskSets.reduce(
            (total, taskSet) => total + taskSet.tasks.reduce((pv, cv) => pv + (cv.isDone && cv.isEvaluated ? 1 : 0), 0),
            0,
          );
          tasks = `${doneTasks} / ${totalTasks}`;
        }

        return {
          interval: `${formatDate(taskSet.from)}-${formatDate(taskSet.due)}`,
          rating: formatRating(questionnaire?.rating),
          rating2: getRatingTotal(this.target, parseISO(taskSet.due))?.toFixed(1) ?? '-',
          questionnaire,
          tasks,
          goals: `${Math.round(taskSetSuccessRate(taskSet) * 100)}%`,
        };
      });
    },
  },
  methods: {
    getFullName(user: UserEntity) {
      return `${user.identity.attributes.firstName} ${user.identity.attributes.lastName}`;
    },
    async showQuestionnaire(questionnaire: QuestionnaireEntity) {
      const fullQuestionnaire = await this.$store.dispatch(new LoadQuestionnaire(questionnaire));
      await this.$store.dispatch(new ShowFeedback(fullQuestionnaire));
    },
  },
});
