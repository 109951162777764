































































































import UserCard from '@/components/UserCard.vue';
import { UserEntity } from '@mentessa/types';
import Vue, { PropType } from 'vue';
import { LoadNextStreamPage, UsersState } from '@/store/users';
import { mapGetters, mapState } from 'vuex';
import UserDetail from '@/components/UserDetail.vue';
import UserDetailMobile from '@/components/UserDetailMobile.vue';
import Loader from '@/components/Loader.vue';
import { SendDirectMessage } from '@/store/messages';
import { ShowNotificationSnackbarAction } from '@/store/ui';

export default Vue.extend({
  name: 'UserStream',
  components: {
    Loader,
    UserDetail,
    UserDetailMobile,
    UserCard,
  },
  props: {
    users: { type: Array as PropType<UserEntity[]> },
    hasFilters: { type: Boolean, default: false },
  },
  data: () => ({
    loadingTrigger: false,
    showUserDetails: false,
    messageDialog: false,
    messageText: '',
    detailCarouselIndex: 0,
    numberOfUsersToShow: 0,
  }),
  computed: {
    ...mapState<UsersState>('users', {
      isLoading: (state: UsersState) => state.stream.isLoading,
    }),
    ...mapGetters('users', {
      isNewPageAvailable: 'isNewPageAvailable',
    }),
    usersViewFrame() {
      return this.users?.slice(0, this.numberOfUsersToShow) ?? [];
    },
  },
  watch: {
    showUserDetails(isDialogOpen) {
      if (!isDialogOpen) {
        this.$router.replace({ name: 'Community' });
      }
    },
  },
  methods: {
    async checkContent() {
      if (this.users.length > this.usersViewFrame.length) {
        this.numberOfUsersToShow += 20;
      }
    },
    async detailCarouselChangeItem(index) {
      if (index === -1) {
        return;
      }
      const desiredId = this.users[index].id.toString();
      const currentId = this.$route.params.id;
      if (index === this.users.length - 1 && this.isNewPageAvailable) {
        await this.$store.dispatch(new LoadNextStreamPage());
      }
      if (desiredId !== currentId) {
        await this.$router.replace({ name: 'UserDetails', params: { id: desiredId.toString() } });
      }
    },
    openUserDetails(userId) {
      const userIndex = this.users.findIndex((user) => user.id === userId);
      this.$router.replace({ name: 'UserDetails', params: { id: userId.toString() } });
      this.detailCarouselIndex = userIndex;
      this.showUserDetails = true;
    },
    closeDetails() {
      this.showUserDetails = false;
    },
    closeMessageDialog() {
      this.messageDialog = false;
      this.messageText = '';
    },
    async sendMessage(userId) {
      const success = await this.$store.dispatch(new SendDirectMessage(userId, this.messageText));
      if (success) {
        this.messageText = '';
        await this.$store.dispatch(new ShowNotificationSnackbarAction(this.$t('notifications.messageSent')));
      } else {
        console.log('Cant send message');
        await this.$store.dispatch(new ShowNotificationSnackbarAction(this.$t('notifications.messageSendError')));
      }
    },
  },
  created() {
    if (this.$route.name === 'UserDetails') {
      this.showUserDetails = true;
    }
  },
});
