import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { UtilsActions, UtilsMutations, UtilsState } from '@/store/utils/types';
import { LocalizedLanguageNames } from '@cospired/i18n-iso-languages';
import Geonames from 'geonames.js';
import { api } from '@/store/api';
import { ShowNotificationSnackbarAction } from '@/store/ui';
import i18n from '@/plugins/i18n';

export const actions: ActionTree<UtilsState, RootState> = {
  async [UtilsActions.LoadLanguages]({ commit }, { locale }) {
    let data = await import(`@cospired/i18n-iso-languages/langs/${locale}.json`);
    if (data == null) {
      data = await import(`@cospired/i18n-iso-languages/langs/en.json`);
    }
    const langNames: LocalizedLanguageNames = data.languages;
    commit(UtilsMutations.SetLanguages, langNames);
    console.log(`Languages for ${locale} loaded`);
  },

  async [UtilsActions.InitGeonames]({ commit }, { locale }) {
    const geonames = Geonames({
      username: 'tihmenev',
      lan: 'de',
      encoding: 'JSON',
    });
    commit(UtilsMutations.SetGeonames, geonames);
    console.log(`Init geonames for ${locale}`);
  },

  async [UtilsActions.SendQuestionnaire]({ dispatch }, { sender, target, taskSetId, answers, text }) {
    try {
      let feedbackCount = 0;
      let feedbackSum = 0;
      answers.forEach((answers) => {
        if (answers.rating > 0) {
          ++feedbackCount;
          feedbackSum += answers.rating / 5;
        }
      });
      await api.post(`questionnaire`, {
        sender: { id: sender.id },
        target: { id: target.id },
        taskSet: { id: taskSetId },
        result: { answers, text },
        rating: feedbackSum / feedbackCount,
      });
      // await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.questionnaireSent').toString()), {
      //   root: true,
      // });
    } catch (e) {
      console.error(e);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },

  async [UtilsActions.LoadQuestionnaire]({ dispatch }, { questionnaire }) {
    try {
      const response = await api.get(`questionnaire/${questionnaire.id}`);
      return response.data;
    } catch (e) {
      console.error('Error while LoadQuestionnaire.', e);
      await dispatch(new ShowNotificationSnackbarAction(i18n.t('notifications.generalError').toString()), {
        root: true,
      });
    }
  },
};
